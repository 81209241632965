<template>
	<div class="formMain">
		<div class="formBodyPositioning">
			<Header />
			<div class="formWidth">
				<div class="formMainModule">
					<div class="formInfomationTitle">
						利润表
					</div>

					<div class="relevantQuestionMain" id="xqBox">
						<div class="relevantQuestion major-balance">
							<!-- 填写说明 -->
							<div class="fillinginstructions">
								<p style="margin-top:9px">
									<img src="../../assets/img/filledTuring/利润表.png">填写说明
								</p>
								<p>
									<!-- 1、最近一年带<span class="reRed">*</span>号科目为估值必填项，其他两年为选填。 -->
								</p>
								<p>
									1、因本表只选取了估值必填的明细科目，如果存在其他明细科目，本表没有对应科目的情况，请将合计数填在对应一级科目处。
								</p>
								<p>
									2、必填项中，如果该科目在对应的会计年度不存在发生额，请填0。
								</p>
							</div>
							<!--单位简介-->
							<div class="float-box-fixed" v-if="theadfixed">
								<tr class="noInput">
									<th>项目</th>
									<th v-for="(item, index) in yearList">{{item}}</th>
								</tr>
							</div>
							<span class="unit">单位：元</span>
							<table class="table" id="tableOne" border="0" style="border-collapse: collapse;">
								<thead v-if="thead">
									<tr class="noInput">
										<th>项目</th>
										<th v-for="(item, index) in yearList">{{item}}</th>
									</tr>
								</thead>
								<tbody>
									<!-- 隐藏占位 -->
									<tr class="trTwo tipTerm" style="visibility: hidden;">
										<td></td>
									</tr>
									<tr class="trOne tipTerm" data-class="income">
										<td>营业收入<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.income[i]"
												@blur="((ev)=>{blur(ev,profit,'income',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('income',i)?formatCurrency.formateNum(profit.income[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="cost">
										<td style="padding-left:10px">减:营业成本<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.cost[i]"
												@blur="((ev)=>{blur(ev,profit,'cost',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('cost',i)?formatCurrency.formateNum(profit.cost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="taxAdditional">
										<td>税金及附加<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.taxAdditional[i]"
												@blur="((ev)=>{blur(ev,profit,'taxAdditional',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('taxAdditional',i)?formatCurrency.formateNum(profit.taxAdditional[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="saleCost">
										<td>销售费用 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.saleCost[i]"
												@blur="((ev)=>{blur(ev,profit,'saleCost',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('saleCost',i)?formatCurrency.formateNum(profit.saleCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="manageCost">
										<td>管理费用<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.manageCost[i]"
												@blur="((ev)=>{blur(ev,profit,'manageCost',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('manageCost',i)?formatCurrency.formateNum(profit.manageCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo" data-class="researchCost">
										<td>研发费用</td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.researchCost[i]"
												@blur="((ev)=>{blur(ev,profit,'researchCost',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('researchCost',i)?formatCurrency.formateNum(profit.researchCost[i],2):'-'}}
											</span>
										</td>
									</tr>
									<tr class="trTwo tipTerm" data-class="financeCost">
										<td>财务费用<span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.financeCost[i]"
												@blur="((ev)=>{blur(ev,profit,'financeCost',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('financeCost',i)?formatCurrency.formateNum(profit.financeCost[i],2):'-'}}
											</span>
										</td>
									</tr>

									<tr class="trFour tipTerm" data-class="interestCost">
										<td style="padding-left:40px">其中:利息支出 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.interestCost[i]"
												@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('interestCost',i)?formatCurrency.formateNum(profit.interestCost[i],2):'-'}}
											</span>
										</td>
									</tr>



									<tr class="trOne" data-class="businessProfit">
										<!-- 复制的营业加:营业外收入	 -->
										<td>营业利润 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.businessProfit[i]"
												@blur="((ev)=>{blur(ev,profit,'businessProfit',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('businessProfit',i)?formatCurrency.formateNum(profit.businessProfit[i],2):'-'}}
											</span>
										</td>
									</tr>

									<tr class="trOne" data-class="totalProfit">
										<td>利润总额 <span class="reRed">*</span></td>
										<td v-for="(item, i) in yearList">
											<!-- <input type="number" data-type="number" v-model="profit.totalProfit[i]" disabled>
                          <span class="showInput">
                            {{judgingCondition('totalProfit',i)?formatCurrency.formateNum(profit.totalProfit[i],2):'-'}}
                          </span> -->

											<input type="number" data-type="number" ref="forbid" v-model="profit.totalProfit[i]"
												@blur="((ev)=>{blur(ev,profit,'totalProfit',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('totalProfit',i)?formatCurrency.formateNum(profit.totalProfit[i],2):'-'}}
											</span>
										</td>
									</tr>

									<tr class="trTwo tipTerm" data-class="incomeTax">
										<td>减:所得税费用 <span class="reRed">*</span></td>

										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.incomeTax[i]"
												@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('incomeTax',i)?formatCurrency.formateNum(profit.incomeTax[i],2):'-'}}
											</span>
										</td>
									</tr>

									<tr class="trOne" data-class="netProfit">
										<td>净利润 <span class="reRed">*</span> </td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" v-model="profit.netProfit[i]" disabled />
											<span class="showInput">
												{{judgingCondition('netProfit',i)?formatCurrency.formateNum(profit.netProfit[i],2):'-'}}
											</span>
											<!-- <input type="number" data-type="number" v-model="profit.netProfit[i]" disabled>
                            <span class="showInput">
                              {{judgingCondition('netProfit',i)?formatCurrency.formateNum(profit.netProfit[i],2):'-'}}
                            </span> -->
										</td>
									</tr>

									<tr class="trOne" data-class="editda">
										<td>折旧与摊销 <span class="reRed">*</span> </td>
										<td v-for="(item, i) in yearList">
											<input type="number" data-type="number" ref="forbid" v-model="profit.editda[i]"
												@blur="((ev)=>{blur(ev,profit,'editda',i)})" @focus="focus($event)" />
											<span class="showInput" @click="inputFocus($event)">
												{{judgingCondition('editda',i)?formatCurrency.formateNum(profit.editda[i],2):'-'}}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="saveBox">
								<span class="closePage" id="saveButton" @click="closeStep">关闭</span>
								<button class="save" id="saveButton" data-isClick="false" @click="saveButton" v-preventReClick>保
									存</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content=""
				@confirmJump="clickConfirmJump()" @cacheTrueMask="clickCacheTrueMaskJump()" @cacheMask="clickCacheMaskJump()">
			</dialogBar>

		</div>
	</div>
</template>

<script>
	// 头部组件引入
	import Header from '../../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../../common/footer/footer.vue';
	import dialogBar from './popue.vue'
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';

	export default {
		data() {
			return {
				yearList: [],
				status: 2, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				id: '',
				lastProfit: '',
				url: '',
				financialTips: false,
				myAlert: false, //上传模板的弹框
				success: false, //上传模板成功的弹框
				error: false, //上传模板错误的弹框
				reportId: '',
				profit: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					businessProfit: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					editda: []
				},
				projectName: '',
				popButonType: "closeData",
				markedWords: "",
				versions: "2",
				scrollTop: 0,
				theadfixed: false,
				thead: true
			}
		},
		components: {
			Header,
			Footer,
			dialogBar
		},
		computed: {
			// 在计算属性获取到这个xqBox的dom元素
			xqBox() {
				return document.querySelector("#xqBox");
			}
		},
		mounted() {
			if (this.$route.query.type == 12) {
				this.projectName = this.domain.turingProject;
			}
			this.pageModify('false');
			// if (this.$route.query.type == 5) {
			// 	this.getTraceProfit()
			// } else {
			this.getProfit()
			// }

		},
		updated() {
			if (this.status == 1) {
				if (this.$refs.forbid) {
					this.$refs.forbid.forEach((item, i) => {
						item.disabled = "true";
						item.style.backgroundColor = '#fff';
						item.nextSibling.style.backgroundColor = '#fff';
						item.nextSibling.style.pointerEvents = 'none';
					})
				}
			} else {
				if (this.$refs.forbid) {
					this.$refs.forbid.forEach((item, i) => {
						item.disabled = "";
					})
				}
			}
		},
		methods: {
			...mapMutations(['pageModify']),
			judgingCondition: function(key, i, index) {
				if (this.profit[key][i] === 0) {
					return true
				} else if (this.profit[key][i] != 0 && this.profit[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur: function(e, data, name, num) { //data：表的字段   name：具体的科目名称   num：科目的具体下标
				e.currentTarget.nextSibling.style.display = 'block';
				var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				var count = String(data[name][num]).length - y; //获取小数点后的个数
				this.pageModify('true');
				if (data[name][num]) {
					if (String(data[name][num]).indexOf(".") != -1) {
						this.$set(this.profit[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					} else {
						if (data[name][num].length > 15) { //判断数据的合理性 
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(this.profit[name], num, '')
						} else {
							this.$set(this.profit[name], num, Number(data[name][num]))
						}
					}
				} else {
					// this.$set(this.profit[name], num, Number(data[name][num]))
					if (data[name][num] == '0') {
						this.$set(this.profit[name], num, Number(data[name][num]))
					} else {
						this.$set(this.profit[name], num, '')
					}
				}
				for (var i = 0; i < this.yearList.length; i++) {
					// 净利润 = 利润总额 - 所得税
					this.profit.netProfit[i] = Number(this.profit.totalProfit[i]) - Number(this.profit.incomeTax[i]);
				}

				//调用缓存共用方法
				// alert()
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentProfit) || (this.$route.query
						.reportId && this.contentProfit && this.isCacheValue == '0')) {
					this.method.caching("profitInfo", JSON.stringify(this.profit), this.$route.query.type, this.$route.query
						.stage, this)
				}
				// this.method.caching("profitInfo",JSON.stringify(this.profit),this.$route.query.type,this.$route.query.stage,this)

			},
			focus: function(e) {

			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},

			getProfit: function(close) {
				var this_ = this;

				this.$post(this.projectName + 'financeProfitInfo/getProfitInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType: this.$route.query.type,
						versions: this.versions,
						stage: this.$route.query.stage
					}))
					.then((res) => {
						var profitKey = Object.keys(this.profit); //获取利润表对象的 key
						if (this.$route.query.type != 7) {
							for (var i = 0; i < res.data.content.year.length; i++) {
								res.data.content.year[i] = res.data.content.year[i] + '度';
							}
						}
						this.yearList = res.data.content.year;
						this.id = res.data.content.id;
						this.contentProfit = res.data.content.profit
						// 调用 关闭 清空数据------------
						this.isCacheValue = res.data.content.isCache
						if (this.isCacheValue == '0' && close == '0') {
							this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this)
						}
						// 调用  关闭 清空数据------------
						if (res.data.content.profit) {
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.myAlert = true;
								this.popButonType = 'cacheType';
								this.markedWords = '您上次填写过估值报告，是否载入缓存信息？';

								// 清除 0.00
								var dataKey = Object.keys(this.profit); //获取对象的 key
								dataKey.forEach((key, idx) => {
									this.yearList.forEach((item, i) => {
										this.profit[key].push("")
									})
								})

							} else { //正式数据
								var returnProfit = Object.keys(JSON.parse(res.data.content
									.profit)) //后台数据返回的利润表对象的 key
								profitKey.forEach((elem, index) => {
									returnProfit.forEach((item, i) => {
										this_.yearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.profit[elem][y] = JSON.parse(res.data
													.content.profit)[elem][y];
											} else if (!this_.profit[elem]) {
												if (this_.yearList.length == 2) {
													this_.profit[elem] = ['', '']
												} else if (this_.yearList.length == 3) {
													this_.profit[elem] = ['', '', '']
												}
											}
										})
									})
								})
							}
						} else {
							var dataKey = Object.keys(this.profit); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.profit[key].push("")
								})
							})
						}
						this.xqBox.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理

					});
			},
			handleScroll: function() {
				var scrollTop = this.xqBox.scrollTop;
				this.scrollTop = scrollTop;
				if (scrollTop > 140) {
					this.theadfixed = true
					this.thead = false
				} else {
					this.theadfixed = false
					this.thead = true
				}
				//  console.log(scrollTop)
			},

			saveButton: function() {
				// console.log(this.profit)
				// return;
				// console.log(this.profit.cost[0])

				if (this.profit.income[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写营业收入，没有请填0。';
					return false;
				}
				if (this.profit.cost[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写营业成本，没有请填0。';
					return false;
				}
				if (this.profit.taxAdditional[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写税金及附加，没有请填0。';
					return false;
				}
				if (this.profit.saleCost[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写销售费用，没有请填0。';
					return false;
				}
				if (this.profit.manageCost[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写管理费用，没有请填0。';
					return false;
				}
				// if (this.profit.researchCost[0]=='') {
				//         this.myAlert = true;
				//         this.popButonType = 'notEmpty';
				//         this.markedWords = '请填写研发费用';
				//   			return false;
				// }
				if (this.profit.financeCost[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写财务费用，没有请填0。';
					return false;
				}
				if (this.profit.interestCost[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写利息支出，没有请填0。';
					return false;
				}
				if (this.profit.businessProfit[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写营业利润，没有请填0。';
					return false;
				}

				if (this.profit.totalProfit[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写利润总额，没有请填0。';
					return false;
				}




				if (this.profit.incomeTax[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写所得税费用，没有请填0。';
					return false;
				}

				if (this.profit.netProfit[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写净利润，没有请填0。';
					return false;
				}

				if (this.profit.editda[0].length == 0) {
					this.myAlert = true;
					this.popButonType = 'notEmpty';
					this.markedWords = '请填写折旧与摊销，没有请填0。';
					return false;
				}


				var this_ = this;
				var params = {
					reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					stage: this.$route.query.stage,
					id: this.id,
					profitInfo: JSON.stringify(this.profit),
					lastProfit: this.profit.netProfit[this.profit.netProfit.length - 1], //最后一年的净利润
					reportType: this.$route.query.type,
					versions: this.versions
				};
				this.url = this.projectName + 'financeProfitInfo/save';
				this.$post(this.url, qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {

							setTimeout(function() {
								this_.$router.push({
									path: '/home',
									query: {
										stage: this_.$route.query.stage,
										type: this_.$route.query.type,
										reportId: res.data.content.valuationReportId
									}
								})
							}, 500)
						}
					});
			},

			closeStep: function() {
				this.myAlert = true;
				this.popButonType = 'closeData';
				this.markedWords = '当前数据尚未保存，是否确定离开？';
			},
			// 关闭弹出层  点击确定回调
			clickConfirmJump() {
				this.getProfit('0');
				this.$router.push({
					path: '/home',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
					}
				})
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getProfit();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this, 'getProfit')
				// this.getProfit();
			}
		}


	}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src='../../assets/css/myGuZhi.css'>
	.mobileBalanceSheet {
		display: none;
	}

	.mobileBalanceSheet>ul li,
	.mobileBalanceSheet table td {
		font-size: 14PX;
	}

	.mobileBalanceSheet table {
		margin-top: 20px;
	}

	.mobileBalanceSheet .courseBold td {
		font-weight: bold;
	}

	.major-balance table>tbody>tr.trThtrr>td:first-of-type {
		padding-left: 53px;
	}

	.major-balance table>tbody>tr.trFour>td:first-of-type {
		padding-left: 40px;
	}

	.reRed {
		color: #FE0000;
		padding-left: 5px;
		padding-right: 5px;
	}

	.balanceSheetTips {
		width: 100%;
		font-size: 12px;
		color: #FE0000;
		margin-left: 10px;
		padding-top: 30px;
		overflow: hidden;
	}
</style>